/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import React, { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import type { Location, Match } from 'react-router-dom';

import { amplifyAnalytics } from '../../amplifyAnalytics';
import { CognitoContext } from '../../CognitoUtils';
import {
  BOI_WALLET_USER_NAME_STORAGE,
  POLICY_NUMBER_STORAGE
} from '../../constants';
import { offWhite, whiteColor } from '../../constants/colors';
import {
  HOME_INSURANCE_TYPE,
  TRAVEL_INSURANCE_TYPE
} from '../../constants/insuranceTypeConstants';
import {
  AUTO_RENEWAL_EXISTING_PAYMENT,
  HOME_BIND_STATUS,
  HOME_BUY_NOW_BIND_RESPONSE,
  PAYMENT_SUCCESS,
  POLICY_RENEWAL
} from '../../constants/sessionStorage/genericStorageIdentifiers';
import {
  getItemFromSessionStorage,
  getObjectFromSessionStorage,
  saveObjectInSessionStorage
} from '../../helpers';
import { bindStatus, buyNowBind } from '../../services/paymentsService';
import type { TravelPaymentDetailsType } from '../../types';
import { UserProfileContext } from '../../UserProfile';
import { GenericErrorPage } from '../GenericErrorPage';
import Loading from '../Loading/Loading';
import PaymentLoading from '../PaymentLoading/PaymentLoading';
import HomePaymentErrorPage from './HomePaymentErrorPage';
import Success from './Success';

const REQUEST_LOADING = 'PaymentThankYou/LOADING';
const REQUEST_SUCCESS = 'PaymentThankYou/SUCCESS';
const REQUEST_FAILURE = 'PaymentThankYou/FAILURE';
const CARD_PAYMENT_FAILURE = 'PaymentThankYou/CARD_PAYMENT_FAILURE';

type RequestState =
  | typeof REQUEST_LOADING
  | typeof REQUEST_SUCCESS
  | typeof REQUEST_FAILURE
  | typeof CARD_PAYMENT_FAILURE;

interface Props {
  location: Location;
  match: Match;
}

const PaymentsThankYouPage = (props: Props) => {
  const spinnerBackgroundColor = isMobile ? offWhite : whiteColor;
  const [requestStatus, setRequestStatus] = useState<RequestState>(
    REQUEST_LOADING
  );
  const [paymentDetails, setPaymentDetails] = useState({});
  const [quoteReference, setQuoteReference] = useState('');
  const { getUsersStoredProfile } = useContext(UserProfileContext);
  const renewalRoute =
    getObjectFromSessionStorage(POLICY_RENEWAL)?.renewal || false;
  const insuranceType = props.match.params.type || '';
  const autoRenewal = JSON.parse(
    getItemFromSessionStorage(AUTO_RENEWAL_EXISTING_PAYMENT)
  );
  const { feature } = useContext(CognitoContext);

  const readPaymentDetailsForTravel = (details: {
    payment_details: {
      totalAmountPaid: number,
      quoteReference: number,
      paymentToken: string,
      premiumId: number
    }
  }) => {
    return {
      product: 'Blue Grizzly Object Model',
      version: '0.1',
      bind_policy: details
    };
  };

  const setRequestStatusFunction = (status: RequestState) => {
    setRequestStatus(status);
    amplifyAnalytics({
      name: `${insuranceType}_BIND_PAYMENT`,
      attributes: {
        status: status,
        username: getItemFromSessionStorage(BOI_WALLET_USER_NAME_STORAGE)
      }
    });
  };

  const readPaymentDetails = () => {
    if (props.location.state && props.location.state.paymentDetails) {
      const details = props.location.state.paymentDetails;
      setPaymentDetails(
        insuranceType !== TRAVEL_INSURANCE_TYPE
          ? details
          : readPaymentDetailsForTravel(details)
      );
    }
  };

  // eslint-disable-next-line complexity
  useEffect(() => {
    readPaymentDetails();
    const policyNumber =
      getObjectFromSessionStorage(`${insuranceType}${POLICY_NUMBER_STORAGE}`) ||
      null;
    const bindStatusResponce =
      getObjectFromSessionStorage('bindStatus') || null;
    const success = getObjectFromSessionStorage(PAYMENT_SUCCESS)?.success;
    if (policyNumber?.referenceNumber) {
      insuranceType === HOME_INSURANCE_TYPE
        ? setQuoteReference(policyNumber?.policy_number)
        : setQuoteReference(policyNumber.referenceNumber);
      setRequestStatusFunction(REQUEST_SUCCESS);
    } else if (
      (insuranceType === HOME_INSURANCE_TYPE &&
        bindStatusResponce?.bind_status === 'IN_PROGRESS') ||
      bindStatusResponce?.error === true
    ) {
      callToBindStatus(getObjectFromSessionStorage('buyNowBindResponse'));
    } else if (success === false) {
      insuranceType === HOME_INSURANCE_TYPE
        ? setRequestStatusFunction(REQUEST_FAILURE)
        : setRequestStatusFunction(CARD_PAYMENT_FAILURE);
    }
  }, []);

  const handlePaymentSuccess = (referenceNumber: any) => {
    setRequestStatusFunction(REQUEST_SUCCESS);
    saveObjectInSessionStorage(`${insuranceType}${POLICY_NUMBER_STORAGE}`, {
      referenceNumber: referenceNumber
    });
    saveObjectInSessionStorage(PAYMENT_SUCCESS, { success: true });
    getUsersStoredProfile(false); // Get the users updated UserProfile
    setQuoteReference(referenceNumber);
  };

  const handlePaymentSuccessHome = (bindStatusResponce: any) => {
    setRequestStatusFunction(REQUEST_SUCCESS);
    saveObjectInSessionStorage(`${insuranceType}${POLICY_NUMBER_STORAGE}`, {
      policy_id: bindStatusResponce?.policy_creation_response_model?.policy_id,
      referenceNumber:
        bindStatusResponce?.policy_creation_response_model?.reference_number,
      policy_number:
        bindStatusResponce?.policy_creation_response_model?.policy_number
    });
    saveObjectInSessionStorage(PAYMENT_SUCCESS, { success: true });
    getUsersStoredProfile(false); // Get the users updated UserProfile
    setQuoteReference(
      bindStatusResponce?.policy_creation_response_model?.policy_number
    );
  };

  const handlePaymentFailure = () => {
    saveObjectInSessionStorage(PAYMENT_SUCCESS, { success: false });
    insuranceType === HOME_INSURANCE_TYPE
      ? setRequestStatusFunction(REQUEST_FAILURE)
      : setRequestStatusFunction(CARD_PAYMENT_FAILURE);
  };

  const delay = (ms: any) => {
    return new Promise((resolve: any) => setTimeout(resolve, ms));
  };

  // eslint-disable-next-line complexity
  const callToBindStatus = async (buyNowBindResponse: any) => {
    let continueLoop = true;
    let bindStatusResponce;
    let delayTime = 2000;
    // eslint-disable-next-line no-constant-condition
    while (continueLoop) {
      try {
        bindStatusResponce = await bindStatus(
          buyNowBindResponse.quote_reference,
          buyNowBindResponse.cognito_user_email
        );
        saveObjectInSessionStorage(HOME_BIND_STATUS, bindStatusResponce);
        if (
          bindStatusResponce?.bind_status === 'COMPLETED' ||
          bindStatusResponce?.bind_status === 'FAILED'
        ) {
          break;
        }
      } catch (error) {
        console.error('Error occurred while fetching bind status:', error);
        break;
      }
      await delay(delayTime);
    }
    if (bindStatusResponce?.bind_status === 'COMPLETED') {
      handlePaymentSuccessHome(bindStatusResponce);
    } else {
      handlePaymentFailure();
    }
  };

  // eslint-disable-next-line complexity
  const checkStatus = async () => {
    if (autoRenewal) {
      const policyNumber =
        getObjectFromSessionStorage(
          `${insuranceType}${POLICY_NUMBER_STORAGE}`
        ) || null;
      setRequestStatusFunction(REQUEST_SUCCESS);
      saveObjectInSessionStorage(`${insuranceType}${POLICY_NUMBER_STORAGE}`, {
        referenceNumber: policyNumber.referenceNumber
      });
      saveObjectInSessionStorage(PAYMENT_SUCCESS, { success: true });
      getUsersStoredProfile(false); // Get the users updated UserProfile
      setQuoteReference(policyNumber.referenceNumber);
    } else {
      if (insuranceType === HOME_INSURANCE_TYPE)
        setRequestStatusFunction(REQUEST_LOADING);
      const buyNowBindResponse = await buyNowBind(
        paymentDetails,
        insuranceType,
        props,
        renewalRoute,
        feature.flags?.bind_upload_enabled
      );
      if (
        buyNowBindResponse &&
        (buyNowBindResponse.reference_number ||
          buyNowBindResponse.quote_reference)
      ) {
        if (
          feature.flags?.bind_upload_enabled === true &&
          insuranceType === HOME_INSURANCE_TYPE
        ) {
          saveObjectInSessionStorage(
            HOME_BUY_NOW_BIND_RESPONSE,
            buyNowBindResponse
          );
          await callToBindStatus(buyNowBindResponse);
        } else {
          handlePaymentSuccess(buyNowBindResponse?.reference_number);
        }
      } else {
        handlePaymentFailure();
      }
    }
  };

  const getLoadingComponent = () =>
    insuranceType === HOME_INSURANCE_TYPE ? (
      <PaymentLoading />
    ) : (
      <Loading
        text="This process may take several minutes, so please be patient and don't refresh or close this tab"
        spinnerBackgroundColor={spinnerBackgroundColor}
      />
    );

  const getSuccessComponent = () => (
    <Success quote_reference={quoteReference} insuranceType={insuranceType} />
  );

  const getErrorComponent = () =>
    insuranceType === HOME_INSURANCE_TYPE ? (
      <HomePaymentErrorPage />
    ) : (
      <GenericErrorPage errorType={insuranceType} />
    );
  const getCardErrorComponent = () => (
    <GenericErrorPage errorType={insuranceType} error="CARD_PAYMENT" />
  );

  const paymentDetailsValidForTravel = (
    paymentDetails: TravelPaymentDetailsType
  ) =>
    paymentDetails.bind_policy &&
    paymentDetails.bind_policy.payment_details != null;

  const validHomePayment = (paymentDetails: TravelPaymentDetailsType) =>
    paymentDetails.payment_details &&
    (paymentDetails.payment_details.quote_reference ||
      !!paymentDetails.payment_details.card_type);

  const validCarPayment = (paymentDetails: TravelPaymentDetailsType) =>
    paymentDetails.payment_details;

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (
      paymentDetailsValidForTravel(paymentDetails) ||
      validHomePayment(paymentDetails) ||
      validCarPayment(paymentDetails) ||
      autoRenewal
    ) {
      checkStatus();
    }
  }, [paymentDetails]);

  const paths = {
    [REQUEST_LOADING]: getLoadingComponent,
    [REQUEST_SUCCESS]: getSuccessComponent,
    [REQUEST_FAILURE]: getErrorComponent,
    [CARD_PAYMENT_FAILURE]: getCardErrorComponent
  };

  const Result = paths[requestStatus];

  return <Result />;
};

export default PaymentsThankYouPage;
