import { REDIRECT_BACK } from '../constants/commonConstants';
import {
  CAR_INSURANCE_TYPE,
  HOME_INSURANCE_TYPE,
  TRAVEL_INSURANCE_TYPE
} from '../constants/insuranceTypeConstants';
import * as storageIdentifiers from '../constants/sessionStorage';
import {
  ALTERNATIVE_POLICY,
  AUTO_RENEWAL_EXISTING_PAYMENT,
  HOME_BIND_STATUS,
  HOME_BUY_NOW_BIND_RESPONSE,
  HOME_PAYMENT_BACK_PRESSED,
  POLICY_RENEWAL
} from '../constants/sessionStorage/genericStorageIdentifiers';
import { RENEWAL_DIRECT_DEBIT_POLICY } from '../constants/sessionStorage/homeStorageIdentifiers';

const removeHomeStorageAfterGetQuote = () => {
  sessionStorage.removeItem(
    `${HOME_INSURANCE_TYPE}${storageIdentifiers.DIRECT_DEBIT_STORAGE}`
  );
  sessionStorage.removeItem(storageIdentifiers.HOME_QUOTE_STORAGE);
  sessionStorage.removeItem(storageIdentifiers.SELECTED_HOME_QUOTE_STORAGE);
  sessionStorage.removeItem(
    storageIdentifiers.HOME_QUOTE_RECALCULATED_VALUES_STORAGE
  );
};

const removeMotorStorageAfterGetQuote = () => {
  sessionStorage.removeItem(
    `${CAR_INSURANCE_TYPE}${storageIdentifiers.GET_QUOTE_RESPONSE_STORAGE}`
  );
  sessionStorage.removeItem(storageIdentifiers.SELECTED_MOTOR_QUOTE_STORAGE);
  sessionStorage.removeItem(
    `${CAR_INSURANCE_TYPE}${
      storageIdentifiers.RECALCULATED_QUOTE_VALUES_STORAGE
    }`
  );
  sessionStorage.removeItem(
    storageIdentifiers.RECALCULATED_QUOTE_RESPONSE_STORAGE
  );
  sessionStorage.removeItem(
    `${CAR_INSURANCE_TYPE}${storageIdentifiers.DIRECT_DEBIT_STORAGE}`
  );
};

const removeTravelStorageAfterGetQuote = () => {
  sessionStorage.removeItem(
    `${TRAVEL_INSURANCE_TYPE}${storageIdentifiers.GET_QUOTE_RESPONSE_STORAGE}`
  );
  sessionStorage.removeItem(
    `${TRAVEL_INSURANCE_TYPE}${
      storageIdentifiers.RECALCULATED_QUOTE_VALUES_STORAGE
    }`
  );
  sessionStorage.removeItem(
    `${TRAVEL_INSURANCE_TYPE}${storageIdentifiers.DECLARATION_DETAILS_STORAGE}`
  );
  sessionStorage.removeItem(
    `${TRAVEL_INSURANCE_TYPE}${storageIdentifiers.DIRECT_DEBIT_STORAGE}`
  );
};

const removeStorageAfterGetQuoteLookup = {
  [HOME_INSURANCE_TYPE]: removeHomeStorageAfterGetQuote,
  [CAR_INSURANCE_TYPE]: removeMotorStorageAfterGetQuote,
  [TRAVEL_INSURANCE_TYPE]: removeTravelStorageAfterGetQuote
};

export const removeSessionStorageAfterGetQuote = insuranceType => {
  if (removeStorageAfterGetQuoteLookup[insuranceType]) {
    removeStorageAfterGetQuoteLookup[insuranceType]();
  }
};

export const removeHomeJourneyStorage = () => {
  sessionStorage.removeItem(storageIdentifiers.HOME_QUOTE_THANK_YOU_STORAGE);
  removeFromSessionStorage(REDIRECT_BACK);
  removeHomeStorageAfterGetQuote();
  removeHomeBindStorage();
};

const removeHomeBindStorage = () => {
  sessionStorage.removeItem(HOME_BUY_NOW_BIND_RESPONSE);
  sessionStorage.removeItem(HOME_BIND_STATUS);
  sessionStorage.removeItem(HOME_PAYMENT_BACK_PRESSED);
};

export const removeRenewalStorage = () => {
  sessionStorage.removeItem(AUTO_RENEWAL_EXISTING_PAYMENT);
  sessionStorage.removeItem(POLICY_RENEWAL);
  sessionStorage.removeItem(ALTERNATIVE_POLICY);
  sessionStorage.removeItem(RENEWAL_DIRECT_DEBIT_POLICY);
};

export const removeCarJourneyStorage = () => {
  sessionStorage.removeItem(
    `${CAR_INSURANCE_TYPE}${storageIdentifiers.GET_QUOTE_VALUES_STORAGE}`
  );
  removeMotorStorageAfterGetQuote();
};

export const removeTravelJourneyStorage = () => {
  sessionStorage.removeItem(
    `${TRAVEL_INSURANCE_TYPE}${storageIdentifiers.GET_QUOTE_VALUES_STORAGE}`
  );
  removeTravelStorageAfterGetQuote();
};

export const removeAllSessionStorage = () => {
  sessionStorage.removeItem(storageIdentifiers.BOI_WALLET_USER_NAME_STORAGE);
  sessionStorage.removeItem(storageIdentifiers.BOI_WALLET_USER_STORAGE);
  removeAllStoredPolicyNumbers();
  removeAllJourneysSessionStorage();
};

export const removeAllJourneysSessionStorage = () => {
  removeHomeJourneyStorage();
  removeRenewalStorage();
  removeCarJourneyStorage();
  removeTravelJourneyStorage();
};

export const getObjectFromSessionStorage = storageKey => {
  const data = sessionStorage.getItem(storageKey);
  if (data) return JSON.parse(data);
  return {};
};

export const getItemFromSessionStorage = storageKey => {
  return sessionStorage.getItem(storageKey);
};

export const saveInSessionStorage = (storageKey, values) => {
  return sessionStorage.setItem(storageKey, values);
};

export const saveObjectInSessionStorage = (storageKey, values) => {
  return sessionStorage.setItem(storageKey, JSON.stringify(values));
};

export const removeAllStoredPolicyNumbers = () => {
  sessionStorage.removeItem(
    `${CAR_INSURANCE_TYPE}${storageIdentifiers.POLICY_NUMBER_STORAGE}`
  );
  sessionStorage.removeItem(
    `${HOME_INSURANCE_TYPE}${storageIdentifiers.POLICY_NUMBER_STORAGE}`
  );
  sessionStorage.removeItem(
    `${TRAVEL_INSURANCE_TYPE}${storageIdentifiers.POLICY_NUMBER_STORAGE}`
  );
};

export const removeFromSessionStorage = storageKey => {
  return sessionStorage.removeItem(storageKey);
};

export const clearAllSessionStorage = () => {
  sessionStorage.clear();
};

export default {
  getObjectFromSessionStorage,
  getItemFromSessionStorage,
  removeAllSessionStorage,
  removeCarJourneyStorage,
  removeHomeJourneyStorage,
  saveInSessionStorage,
  removeFromSessionStorage,
  removeAllJourneysSessionStorage,
  removeSessionStorageAfterGetQuote,
  removeAllStoredPolicyNumbers
};
