// @flow
import './NavigationGroup.scss';

import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { NavigationItem } from '../NavigationItem';

const className = 'c-NavigationGroup';

const getNavigationLink = (
  displayText: string,
  link: string,
  index: number,
  style: string
) => (
  <Link
    className={`${className}${style}`}
    key={`navigation-group-item-${index}`}
    to={link}
  >
    {displayText}
  </Link>
);

const getNavigationActionItem = (
  displayText: string,
  action: () => mixed,
  index: number
) => (
  <div
    className={`${className}__item`}
    onClick={action}
    key={`navigation-group-item-${index}`}
  >
    {displayText}
  </div>
);

const getNavigationEmptyItem = (displayText: string, index: number) => (
  <div className={`${className}__item`} key={`navigation-group-item-${index}`}>
    {displayText}
  </div>
);

const getNavigationItem = (menuOpen: boolean) => (
  item: NavigationItem,
  index: number
) => {
  if (item.list) return <NavigationDropdown item={item} menuOpen={menuOpen} />;
  if (item.link)
    return getNavigationLink(item.displayText, item.link, index, '__item');
  else if (item.action)
    return getNavigationActionItem(item.displayText, item.action, index);
  return getNavigationEmptyItem(item.displayText, index);
};

const NavigationDropdown = ({
  item,
  menuOpen
}: {
  item: NavigationItem,
  menuOpen: boolean
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const listItems = item.listItems || [];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIsOpen(false);
  }, [menuOpen]);

  return (
    <>
      <div className={`${className}__item`} onClick={toggleDropdown}>
        {item.displayText}
        {!isOpen ? (
          <FontAwesomeIcon
            icon={faChevronDown}
            className={`${className}__buttonIcon`}
          />
        ) : (
          <FontAwesomeIcon
            icon={faChevronUp}
            className={`${className}__buttonIcon`}
          />
        )}
      </div>
      {isOpen && (
        <>
          {listItems.map((subItem: NavigationItem, index: number) => {
            const displayText = subItem.displayText || '';
            const link = subItem.link || '';

            return getNavigationLink(displayText, link, index, '__item');
          })}
        </>
      )}
    </>
  );
};

interface Props {
  menuItems: Array<NavigationItem>;
  id: string;
  menuOpen: boolean;
}

const NavigationGroup = ({ menuItems, id, menuOpen }: Props) => (
  <div className={className} id={id}>
    {menuItems.map(getNavigationItem(menuOpen))}
  </div>
);

export default NavigationGroup;
