/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './index.scss';

import type { Node } from 'react';
import { useLayoutEffect, useRef, useState } from 'react';
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory, useLocation } from 'react-router-dom';

import { LOCATION_HISTORY_STORAGE } from '../../constants';
import { getItemFromSessionStorage, saveInSessionStorage } from '../../helpers';
import { routes } from '../../routes';
import DesktopHeader from '../DesktopHeader';
import ErrorBoundary from '../ErrorBoundary';
import MobileHeader from '../MobileHeader';

interface AppProps {
  children?: Node;
}

const App = ({ children }: AppProps) => {
  const className = 'c-App';

  const location = useLocation();
  const history = useHistory();
  const ref = useRef(null);
  const maxWidth = 992;

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    return history.listen((location: any) => {
      onUrlChange();
    });
  }, [history]);

  const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  };

  const [width] = useWindowSize();

  const onUrlChange = () => {
    const data = JSON.parse(
      getItemFromSessionStorage(LOCATION_HISTORY_STORAGE)
    );
    // If the pathname hasn't been changed don't add an entry to the location history
    if (data && location.pathname === data[data.length - 1]) return;

    const newData = data ? [...data, location.pathname] : [location.pathname];
    saveInSessionStorage(LOCATION_HISTORY_STORAGE, JSON.stringify(newData));
  };

  return (
    <ErrorBoundary>
      <div className={`${className}`} ref={ref}>
        {isMobile || width < maxWidth ? (
          <MobileHeader routes={routes} />
        ) : (
          <DesktopHeader routes={routes} />
        )}
        <div className={`${className}__childrenContainer`}>{children}</div>
      </div>
    </ErrorBoundary>
  );
};

export default App;
