"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = XIcon;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function XIcon() {
  return _react2.default.createElement("svg", {
    width: "24",
    height: "24",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, _react2.default.createElement("mask", {
    id: "mask0_493_5817",
    maskType: "alpha",
    maskUnits: "userSpaceOnUse",
    x: "0",
    y: "0",
    width: "24",
    height: "24"
  }, _react2.default.createElement("rect", {
    width: "24",
    height: "24",
    fill: "#D9D9D9"
  })), _react2.default.createElement("g", {
    mask: "url(#mask0_493_5817)"
  }, _react2.default.createElement("path", {
    d: "M6.4 18.6538L5.34625 17.6L10.9462 12L5.34625 6.4L6.4 5.34625L12 10.9462L17.6 5.34625L18.6538 6.4L13.0537 12L18.6538 17.6L17.6 18.6538L12 13.0537L6.4 18.6538Z",
    fill: "#161616"
  })));
}