// @flow

import './DesktopHeader.scss';

import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import type { Location } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { noPurchasePolicy } from '../../constants/commonConstants';
import {
  CAR_INSURANCE_TYPE,
  HOME_INSURANCE_TYPE,
  TRAVEL_INSURANCE_TYPE
} from '../../constants/insuranceTypeConstants';
import { localPhoneNumber } from '../../constants/phoneNumbers';
import type { BOIRoute } from '../../routes';
import { routes } from '../../routes';
import type { ListItems, MenuItem } from '../../types/commonTypes';
import type { UserProfileType } from '../../types/profile';

interface Props {
  routes: { [key: string]: BOIRoute };
  location: Location;
  isLoggedIn: boolean;
  userProfile?: UserProfileType;
}

interface State {
  displayMenu: boolean;
  activeItem: string;
  openDropdownIndex: any;
  underLink: string;
}

const noPurchaseText = `${noPurchasePolicy}. Please call ${localPhoneNumber} for assistance.`;
class SubHeading extends Component<Props, State> {
  className = 'c-SubHeading';

  state = {
    displayMenu: false,
    activeItem: '/',
    openDropdownIndex: null,
    underLink: ''
  };

  showDropdownMenu = (index: number, event: any) => {
    event.preventDefault();
    this.setState({ openDropdownIndex: index }, () => {
      document.addEventListener('click', this.hideDropdownMenu);
    });
  };

  hideDropdownMenu = (event: any) => {
    if (event && event.target.closest('.dropdownMenu')) {
      return; // If clicking inside the dropdown, do nothing
    }
    this.setState({ openDropdownIndex: null }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });
  };

  getUserNotLoggedInOrHasNoProfileOptions = () => {
    const loginPageUrl = routes.loginPage.url;
    const { userProfile, isLoggedIn } = this.props;
    if (!isLoggedIn) {
      return [
        { displayText: 'Car', link: loginPageUrl },
        { displayText: 'Home', link: loginPageUrl },
        { displayText: 'Travel', link: loginPageUrl }
      ];
    }
    // If the user does not have first name in their profile then their profile hasn't been set
    if (!userProfile || !userProfile.email) {
      return [{ displayText: noPurchaseText }];
    }
    return [];
  };

  getUserLoggedInOptions = () => {
    const manageMyInsuranceProductURL = routes.viewMultiplePolicies.url;
    const { userProfile = {} } = this.props;
    const items = [];
    if (userProfile.purchasedInsuranceTypes[CAR_INSURANCE_TYPE])
      items.push({
        displayText: 'Car',
        link: `${manageMyInsuranceProductURL}car`
      });
    if (userProfile.purchasedInsuranceTypes[HOME_INSURANCE_TYPE])
      items.push({
        displayText: 'Home',
        link: `${manageMyInsuranceProductURL}home`
      });
    if (userProfile.purchasedInsuranceTypes[TRAVEL_INSURANCE_TYPE])
      items.push({
        displayText: 'Travel',
        link: `${manageMyInsuranceProductURL}travel`
      });
    return items;
  };

  getManageMyInsuranceDropdownItems = () => {
    let items = this.getUserNotLoggedInOrHasNoProfileOptions();
    // If the user is not logged in or has no profile return items for the dropdown
    if (items.length > 0) {
      return items;
    }
    items = this.getUserLoggedInOptions();
    // If the user has not purchased any insurance give them a default value with no link
    if (items.length === 0) items.push({ displayText: noPurchaseText });
    return items;
  };

  getNewQuoteDropdownItems = () => {
    return [
      {
        displayText: 'Car',
        link: `${routes.getGenericQuotePage.url}car`
      },
      {
        displayText: 'Home',
        link: `${routes.getQuotePage.url}aboutYou`
      },
      {
        displayText: 'Travel',
        link: `${routes.getGenericQuotePage.url}travel`
      }
    ];
  };

  getMenuContent = () => {
    const manageMyInsuranceProductURL = routes.viewMultiplePolicies.url;
    return [
      {
        displayText: 'Home',
        link: routes.wallet.url,
        list: false
      },
      {
        displayText: 'New Quote',
        list: true,
        link: [
          {
            link1: 'getquote/aboutYou',
            link2: '/getgenericquote/'
          }
        ],
        listItems: this.getNewQuoteDropdownItems()
      },
      {
        displayText: 'Manage My Insurance',
        list: true,
        link: manageMyInsuranceProductURL /* This link is here for styling purposes to highlight when this section of the menu is selected */,
        listItems: this.getManageMyInsuranceDropdownItems()
      },
      {
        displayText: 'Renew',
        link: `${manageMyInsuranceProductURL}home`,
        list: false
      }
    ];
  };

  setClassName = (pathname: string = '', item: MenuItem = {}) => {
    let links = [];

    if (Array.isArray(item.link)) {
      item.link.forEach((linkObj: any) => {
        links.push(...Object.values(linkObj));
      });
    } else {
      links = [item.link];
    }

    const isUnderLink = links.some((link: any) =>
      pathname.includes(String(link))
    );

    return isUnderLink && this.state.underLink === item.displayText
      ? `${this.className}__dropdownRowUnderLink`
      : `${this.className}__dropdownRow`;
  };

  handleLinkClick = (item: MenuItem = {}) => {
    this.setState({ underLink: item.displayText });
  };

  // eslint-disable-next-line complexity
  getClassName = (pathname: string = '', item: MenuItem = {}) => {
    if (item.link === '/wallet') {
      return pathname === item.link
        ? `${this.className}__underLineLink`
        : `${this.className}__link`;
    } else {
      return this.state.underLink === item.displayText && pathname === item.link
        ? `${this.className}__underLineLink`
        : `${this.className}__link`;
    }
  };

  render() {
    const { pathname } = this.props.location;
    const menuContent = this.getMenuContent();
    const renderLinks = (pathname: string) => {
      return (
        <div className={`${this.className}__linksContainer`}>
          {// eslint-disable-next-line complexity
          menuContent.map((item: MenuItem, index: number) => {
            if (item.list) {
              return (
                <div
                  key={`desktop-menu-item-${index}`}
                  className={this.setClassName(pathname, item)}
                  onClick={(event: SyntheticEvent<HTMLInputElement>) => {
                    this.showDropdownMenu(index, event);
                    this.handleLinkClick(item);
                  }}
                >
                  <div className={`${this.className}__link`} key={index}>
                    {item.displayText}
                  </div>
                  <div className={`${this.className}__paddingCheveron`}>
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      className={`${this.className}__buttonIcon`}
                    />
                  </div>
                  {this.state.openDropdownIndex === index ? (
                    <div className={`${this.className}__dropdownMenu`}>
                      {item.listItems.map(
                        (listItem: ListItems, index: number) => {
                          return (
                            <Link key={index} to={listItem.link}>
                              <div className={`${this.className}__item`}>
                                {listItem.displayText}
                              </div>
                            </Link>
                          );
                        }
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              );
            }
            return (
              <div
                key={`desktop-menu-item-${index}`}
                className={`${this.className}__anchorLink`}
                onClick={() => {
                  this.handleLinkClick(item);
                }}
              >
                <Link
                  to={item.link}
                  key={index}
                  className={this.getClassName(pathname, item)}
                >
                  {item.displayText}
                </Link>
              </div>
            );
          })}
        </div>
      );
    };

    return <div className={`${this.className}`}>{renderLinks(pathname)}</div>;
  }
}

export default withRouter(SubHeading);
