/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

import '../../styles/commonPageStyles.scss';
import './ViewHomeMultipleQuotesPage.scss';

import { TitleWithUnderLine } from '@boi/core/lib';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import AnimatedSpinner from '../../components/AnimatedSpinner/AnimatedSpinner';
import GenericErrorComponent from '../../components/GenericErrorComponent';
import { MobileBackButton } from '../../components/MobileBackButton';
import { PropertyMapper } from '../../components/PremiumBreakdowns/HomePremiumBreakdown/AmountMapper';
import ProgressBar from '../../components/ProgressBar';
import QuoteOverviewContainer from '../../components/QuoteOverviewContainer/QuoteOverviewContainer';
import QuoteRenewalOverviewContainer from '../../components/QuoteRenewalOverviewContainer/QuoteRenewalOverviewContainer';
import {
  commonPageStylesIdentifier,
  HOME_QUOTE_STORAGE,
  SELECTED_HOME_QUOTE_STORAGE,
  TOGGLE_OPTIONAL_EXTRAS
} from '../../constants';
import { REDIRECT_BACK } from '../../constants/commonConstants';
import { INSURER_TYPE, QUOTE_REFERENCE } from '../../constants/home';
import {
  ABOUT_YOU,
  CODE_VALUES,
  premiumBreakDownConstants
} from '../../constants/insuranceTypeConstants';
import {
  HOME_JOURNEY,
  JOURNEY_GET_QUOTE,
  JOURNEY_YOUR_QUOTE,
  RENEWAL_JOURNEY_LABEL_TEXT,
  STAGES_HOME_JOURNEY,
  STAGES_RENEWAL_JOURNEY
} from '../../constants/journey';
import { localPhoneNumber } from '../../constants/phoneNumbers';
import { POLICY_RENEWAL } from '../../constants/sessionStorage/genericStorageIdentifiers';
import { RENEWAL_DIRECT_DEBIT_POLICY } from '../../constants/sessionStorage/homeStorageIdentifiers';
import { CommonPageStylesDesktopBackButton } from '../../constants/styleConstants/commonClassNames';
import {
  getObjectFromSessionStorage,
  removeFromSessionStorage
} from '../../helpers';
import {
  saveInSessionStorage,
  saveObjectInSessionStorage
} from '../../helpers/sessionStorageHelper';
import { routes } from '../../routes';
import { getRenewalQuote } from '../../services/homeServices';

const documentsLink =
  '//personalbanking.bankofireland.com/insure-and-protect/insurance/home-insurance-quote/important-documents/';

const ipidLabel = () => (
  <>
    Important Documents{' '}
    <a href={documentsLink} target="_blank" rel="noopener noreferrer">
      Click here
    </a>
  </>
);

const benefits = [
  { label: () => 'Pay by direct debit at no extra cost ', tick: true },
  { label: () => '24 hour helpline in a home emergency ', tick: true },
  { label: () => 'Loss of keys/lock replacement', tick: true },
  { label: () => 'Fire brigade charges, up to €2,500', tick: true },
  { label: () => ipidLabel(), tick: true }
];

const renewalBenefits = [
  {
    label: () => 'Please review policy documents for full details',
    tick: true
  },
  {
    label: () => 'Product coverage is comparable across our insurer panel',
    tick: true
  },
  { label: () => 'Pay by Direct Debit at no extra cost', tick: true },
  { label: () => '24 hour helpline in home emergency', tick: true }
];

const defaultQuoteObj = {
  RSA: {
    logo: 'asset/rsa_logo.jpg',
    underwrittenClass: 'rsaLogo',
    price: '',
    INSURER_TYPE: INSURER_TYPE.RSA
  },
  FBD: {
    logo: 'asset/fbd_logo.jpg',
    underwrittenClass: 'fbdLogo',
    price: '',
    INSURER_TYPE: INSURER_TYPE.FBD
  }
};

// eslint-disable-next-line complexity
const ViewHomeMultipleQuotesPage = props => {
  const className = 'c-ViewMultipleQuotePage';
  // eslint-disable-next-line react/prop-types
  const { history, match } = props;
  useEffect(() => {
    if (getObjectFromSessionStorage(SELECTED_HOME_QUOTE_STORAGE)) {
      removeFromSessionStorage(`${SELECTED_HOME_QUOTE_STORAGE}`);
    }
    saveInSessionStorage(REDIRECT_BACK, true);
  }, []);

  const [quotesFinal, setQuotes] = useState([]);
  const [renewalQuotes, setRenewalQuotes] = useState({});
  const [renewalError, setRenewalError] = useState(false);
  const [showMultipleQuote, setShowMultipleQuote] = useState(true);
  const renewalPolicy = getObjectFromSessionStorage(POLICY_RENEWAL);

  // eslint-disable-next-line complexity
  const getQuoteResponse = async () => {
    const isHomeQuote = getObjectFromSessionStorage(HOME_QUOTE_STORAGE);
    if (renewalPolicy.renewal && Object.keys(isHomeQuote).length === 0) {
      const data = await getRenewalQuote(
        { policy_code: renewalPolicy.reference_number },
        match,
        { history }
      );
      if (!data.error) {
        saveObjectInSessionStorage(`${HOME_QUOTE_STORAGE}`, { response: data });
      } else {
        setRenewalError(true);
      }
    }
    const homeQuote = getObjectFromSessionStorage(`${HOME_QUOTE_STORAGE}`);
    if (homeQuote.response && homeQuote.response.length > 0) {
      if (homeQuote?.response[0]?.quote_reference) {
        saveObjectInSessionStorage(
          QUOTE_REFERENCE,
          homeQuote.response[0].quote_reference
        );
      }
      setQuoteDetailsFromResponse(homeQuote.response);
    } else {
      const fbdQuote = {
        ...defaultQuoteObj[INSURER_TYPE.FBD],
        price: 0
      };
      const rsaQuote = {
        ...defaultQuoteObj[INSURER_TYPE.RSA],
        price: 0
      };
      setQuotes([fbdQuote, rsaQuote]);
    }
  };

  const updateQuote = responseArr => {
    const quotesData = [];
    let quoteObj = {};
    responseArr.forEach(item => {
      quoteObj = {
        ...item,
        ...defaultQuoteObj[item.insurer],
        price: getQuoteTotal(item.insurer, responseArr)
      };
      quotesData.push(quoteObj);
    });
    return quotesData;
  };

  // eslint-disable-next-line complexity
  const setQuoteDetailsFromResponse = async response => {
    const alreadySelected = Object.keys(
      getObjectFromSessionStorage(SELECTED_HOME_QUOTE_STORAGE)
    ).length;
    const onlyOneQuoteNumber = () => {
      let goodCount = 0;
      let savedNumber = 0;
      const quoteGood = quote =>
        !quote.acceptance_notifications ||
        (quote.acceptance_notifications &&
          quote.acceptance_notifications.length === 0);
      response.forEach((quote, index) => {
        if (quoteGood(quote)) {
          goodCount++;
          savedNumber = index + 1;
        }
      });
      return goodCount > 1 ? 0 : savedNumber;
    };
    const oneQuoteResult = onlyOneQuoteNumber();
    if (oneQuoteResult > 0) {
      if (alreadySelected && !renewalPolicy.renewal) {
        removeFromSessionStorage(`${SELECTED_HOME_QUOTE_STORAGE}`);
        props.history.push(`${routes.getQuotePage.url}${ABOUT_YOU}`);
      } else {
        const oneQuoteResultAsIndex = oneQuoteResult - 1;
        if (renewalPolicy.renewal) {
          setShowMultipleQuote(false);
          const updatedQuote = await updateQuote([
            response[oneQuoteResultAsIndex]
          ]);
          setRenewalQuotes(updateQuoteSequence(updatedQuote));
        } else {
          goToViewQuotePage(response[oneQuoteResultAsIndex].insurer);
        }
      }
    } else {
      const updatedQuotes = await updateQuote(response);
      if (updatedQuotes.length > 0) {
        if (renewalPolicy.renewal) {
          setRenewalQuotes(updateQuoteSequence(updatedQuotes));
        } else {
          setQuotes(updatedQuotes);
        }
      }
    }
  };

  const updateQuoteSequence = quotesData => {
    const renewalQuote = quotesData.find(item => {
      if (item.current_insurer_renewal_quote) {
        return item;
      }
    });
    const filteredArray = quotesData.filter(item => {
      if (!item.current_insurer_renewal_quote) {
        return item;
      }
    });
    const returnObj = {
      currentRenewal: renewalQuote,
      alternativeQuote: filteredArray || []
    };
    return returnObj;
  };

  const getQuoteTotal = (quoteType, response) => {
    const quote = response.find(quote => quote.insurer === quoteType);
    if (quote.premium_details.length > 0) {
      const element = quote.premium_details.find(item => {
        if (item.code === CODE_VALUES[quoteType]) {
          return item;
        }
      });
      const propertyIdentifier =
        PropertyMapper[quoteType][premiumBreakDownConstants.INCLEVY] === 'AMT'
          ? 'amount'
          : 'running_total';
      return element[propertyIdentifier] ? element[propertyIdentifier] : 0;
    } else {
      return 0;
    }
  };

  const goToViewQuotePage = (selectedQuoteType, alternativePolicy) => {
    const state = getObjectFromSessionStorage(`${HOME_QUOTE_STORAGE}`);
    const selected = state.response.find(
      quote => quote.insurer === selectedQuoteType
    );
    state.selectedQuoteType = selectedQuoteType;
    saveObjectInSessionStorage(SELECTED_HOME_QUOTE_STORAGE, {
      ...selected,
      ...alternativePolicy
    });
    saveInSessionStorage(
      RENEWAL_DIRECT_DEBIT_POLICY,
      selected?.direct_debit_policy
    );
    props.history.push({
      state,
      pathname: '/wallet/viewQuote/home'
    });
  };

  // eslint-disable-next-line complexity
  const mobileBackButtonAndTitle = () => {
    return (
      <>
        <div
          className={`${commonPageStylesIdentifier} ${CommonPageStylesDesktopBackButton}`}
        >
          <MobileBackButton history={history} />
        </div>
        <ProgressBar
          stage={renewalPolicy.renewal ? JOURNEY_GET_QUOTE : JOURNEY_YOUR_QUOTE}
          labelText={
            renewalPolicy.renewal ? RENEWAL_JOURNEY_LABEL_TEXT : HOME_JOURNEY
          }
          stages={
            renewalPolicy.renewal ? STAGES_RENEWAL_JOURNEY : STAGES_HOME_JOURNEY
          }
        />
        {!renewalPolicy?.renewal && (
          <div className={`${commonPageStylesIdentifier}__pageTitle`}>
            <TitleWithUnderLine>Your Quotes</TitleWithUnderLine>
          </div>
        )}
      </>
    );
  };

  const showSingleQuote = () => {
    return (
      <div className={`${className}`}>
        <>
          {mobileBackButtonAndTitle()}
          {Object.keys(renewalQuotes).length < 2 && (
            <AnimatedSpinner size="large" />
          )}
          <QuoteRenewalOverviewContainer
            classes={'c-QuoteOverview'}
            goToViewQuotePage={(insurerType, alternativePolicy) => {
              goToViewQuotePage(insurerType, alternativePolicy);
            }}
            quotesFinal={renewalQuotes}
            benefits={renewalBenefits}
          />
        </>
      </div>
    );
  };

  // eslint-disable-next-line complexity
  const showMultipleQuotes = () => {
    return (
      <div className={`${className}`}>
        <>
          {mobileBackButtonAndTitle()}
          {renewalPolicy.renewal && (
            <>
              {Object.keys(renewalQuotes)?.length < 2 && (
                <AnimatedSpinner size="large" />
              )}
              <QuoteRenewalOverviewContainer
                classes={'c-QuoteOverview'}
                goToViewQuotePage={(insurerType, alternativePolicy) => {
                  goToViewQuotePage(insurerType, alternativePolicy);
                }}
                quotesFinal={renewalQuotes}
                benefits={renewalBenefits}
              />
            </>
          )}

          {!renewalPolicy.renewal && quotesFinal.length > 0 && (
            <>
              {quotesFinal?.length === 0 && <AnimatedSpinner size="large" />}
              <QuoteOverviewContainer
                classes={className}
                goToViewQuotePage={(insurerType, alternativePolicy) => {
                  goToViewQuotePage(insurerType, alternativePolicy);
                }}
                quotesFinal={quotesFinal}
                benefits={benefits}
              />
            </>
          )}
        </>
      </div>
    );
  };

  useEffect(() => {
    removeFromSessionStorage(TOGGLE_OPTIONAL_EXTRAS);
    getQuoteResponse();
  }, []);

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        removeFromSessionStorage(TOGGLE_OPTIONAL_EXTRAS);
        removeFromSessionStorage(`${SELECTED_HOME_QUOTE_STORAGE}`);
      }
    };
  }, [history]);
  return (
    <>
      {renewalError && (
        <>
          <GenericErrorComponent title={'Your Renewal'} history={props.history}>
            <h5>
              Unfortunately your renewal quote cannot be displayed right now,
              please contact us for further assistance on
              <a href={`tel:${localPhoneNumber}`}> {localPhoneNumber}</a>
            </h5>
          </GenericErrorComponent>
        </>
      )}
      {!renewalError && (
        <>
          {showMultipleQuote && showMultipleQuotes()}
          {!showMultipleQuote && showSingleQuote()}
        </>
      )}
    </>
  );
};

ViewHomeMultipleQuotesPage.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default ViewHomeMultipleQuotesPage;
