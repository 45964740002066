// @flow

import './Navigation.scss';

import { BOILogoNoSpace, HamburguerMenu, XIcon } from '@boi/core/lib';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { black, greyDark } from '../../constants/colors';
import NavigationGroup from './NavigationGroup';
import { NavigationItem } from './NavigationItem';

interface Props {
  menuItems: Array<Array<NavigationItem>>;
}

const Navigation = ({ menuItems }: Props) => {
  const [isOpen, toggleOpen] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const location = useLocation();
  const prevLocation = useRef(location.pathname);

  const className = 'c-Navigation';

  const setMenuState = (state: boolean) => {
    if (isDisabled) return;
    setDisabled(true);
    setTimeout(() => setDisabled(false), 500);
    toggleOpen(state);
  };

  const openMenu = () => setMenuState(true);

  const closeMenu = () => setMenuState(false);

  useEffect(() => {
    const handleUrlChange = () => {
      closeMenu();
    };

    if (prevLocation.current !== location.pathname) {
      handleUrlChange();
      prevLocation.current = location.pathname;
    }
  }, [location.pathname]);

  const getNavigationGroup = (isOpen: boolean) => (
    items: Array<NavigationItem>,
    index: number
  ) => (
    <NavigationGroup
      id={`NavigationGroup-${index}`}
      data-testid={`NavigationGroup-${index}`}
      key={`nagivation-group-${index}`}
      menuItems={items}
      menuOpen={isOpen}
      closeMenu={closeMenu}
    />
  );

  return (
    <div>
      <div onClick={openMenu} id="openMenu">
        <HamburguerMenu color={greyDark} />
      </div>
      <div
        className={`${className} ${isOpen ? 'c-Navigation--open' : ''}`}
        id="Navigation"
      >
        <div
          className={`${className}__overlay ${
            isOpen ? 'c-Navigation__overlay--open' : ''
          }`}
        >
          <div
            className={`${className}__content ${
              isOpen ? 'c-Navigation__content--open' : ''
            }`}
          >
            <div className={`${className}__contentGroup`}>
              <div className={`${className}--with-padding`}>
                <div className="c-MobileHeader__barIcon" id="closeMenu">
                  <HamburguerMenu color={greyDark} />
                </div>
                <div className="c-MobileHeader__barIcon" onClick={closeMenu}>
                  <XIcon color={black} />
                </div>
              </div>

              <div
                className={`${className}--with-padding ${className}__contentGroup ${className}--blue`}
              >
                Menu
              </div>

              {menuItems.map(getNavigationGroup(isOpen))}
            </div>
            <div className={`${className}__boiLogo ${className}--with-padding`}>
              <BOILogoNoSpace />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
