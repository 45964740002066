/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import { basePath, isAnalyticsEnabled } from '../constants/commonConstants';
import {
  CAR_INSURANCE_TYPE,
  HOME_INSURANCE_TYPE,
  TRAVEL_INSURANCE_TYPE
} from '../constants/insuranceTypeConstants';
import { makeRequest } from '../helpers';

const typeNames = {
  [`${CAR_INSURANCE_TYPE}`]: 'Car',
  [`${HOME_INSURANCE_TYPE}`]: 'Home'
};

const sendAnalyticsEvent = (bindPromise, insuranceType, paymentDetails) => {
  if (isAnalyticsEnabled) {
    bindPromise.then(response => {
      if (!response.error) {
        window.dataLayer.push({
          event: 'checkout',
          transactionId: paymentDetails.payment_details.quote_reference,
          transactionAffiliation: 'Bank of Ireland',
          transactionTotal: paymentDetails.payment_details.price,
          transactionProducts: [
            {
              sku: `${typeNames[insuranceType]} Insurance`,
              name: `${typeNames[insuranceType]} Insurance GWS`,
              category: 'Consumer - Insurance',
              price: paymentDetails.payment_details.price,
              quantity: 1
            }
          ],
          catchAll: paymentDetails.payment_details.product_selected || ''
        });
      }
    });
  }
};

// eslint-disable-next-line complexity
const getBindUrl = (basePath, insuranceType, renewal, featureFlag) => {
  if (insuranceType === CAR_INSURANCE_TYPE) {
    return `${basePath}/motor/motor_quotes/bind`;
  }
  if (insuranceType === TRAVEL_INSURANCE_TYPE) {
    return `${basePath}/travel/travel_quotes/bind`;
  }
  if (insuranceType === HOME_INSURANCE_TYPE && featureFlag === true) {
    return renewal
      ? `${basePath}/home/applied_home_renewal/bind_upload`
      : `${basePath}/home/applied_home_quotes/bind_upload`;
  }
  if (insuranceType === HOME_INSURANCE_TYPE) {
    return renewal
      ? `${basePath}/home/applied_home_renewal/bind`
      : `${basePath}/home/applied_home_quotes/bind`;
  }
  return `${basePath}/${insuranceType}/${insuranceType}_quotes/bind`;
};

export const buyNowBind = async (
  paymentDetails,
  insuranceType = 'home',
  props = {},
  renewal = false,
  featureFlag = true
) => {
  const paymentRequest = makeRequest({
    method: 'POST',
    url: getBindUrl(basePath, insuranceType, renewal, featureFlag),
    payload: paymentDetails,
    history: props.history
  });
  if (insuranceType !== TRAVEL_INSURANCE_TYPE)
    sendAnalyticsEvent(paymentRequest, insuranceType, paymentDetails);
  return await paymentRequest;
};

export const bindStatus = async (quoteReference, email, props = {}) => {
  const paymentStatus = makeRequest({
    method: 'POST',
    url: `${basePath}/home/applied_home_quotes/bind_status`,
    payload: {
      quote_reference: quoteReference,
      user_email: email
    },
    history: props.history
  });
  return await paymentStatus;
};
