/* eslint-disable complexity */
/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './GetQuoteThankYouPage.scss';

import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import type { Match, RouterHistory } from 'react-router-dom';

import { amplifyAnalytics } from '../../amplifyAnalytics';
import type AboutYouFormValues from '../../components/Home/AboutYouForm';
import { HOME_QUOTE_THANK_YOU_STORAGE } from '../../constants';
import { offWhite, whiteColor } from '../../constants/colors';
import { REDIRECT_BACK } from '../../constants/commonConstants';
import { HOME_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { BOI_WALLET_USER_NAME_STORAGE, GET_QUOTE_VALUES_STORAGE, SUBMITTED_GET_QUOTE_STORAGE } from '../../constants/sessionStorage';
import { getItemFromSessionStorage, removeFromSessionStorage, saveInSessionStorage } from '../../helpers';
import { mapGetQuote } from '../../mappings/home/MapGetQuote.js';
import { postHomeQuote } from '../../services/homeServices';
import type { PayloadType } from '../../types';
import { GenericErrorPage } from '../GenericErrorPage';
import Loading from '../Loading/Loading';
import Success from './Success';

const REQUEST_LOADING = 'GetQuoteThankYou/LOADING';
const REQUEST_SUCCESS = 'GetQuoteThankYou/SUCCESS';
const REQUEST_FAILURE = 'GetQuoteThankYou/FAILURE';

type RequestState =
  | typeof REQUEST_LOADING
  | typeof REQUEST_SUCCESS
  | typeof REQUEST_FAILURE;

interface Props {
  history: RouterHistory;
  location: any;
  match: Match;
}

interface locationState {
  aboutYouObj: AboutYouFormValues;
}

//need to do some error checking to make sure three objects exsist
const formDataReceived = (state: locationState) => {
  return state && state.aboutYouObj ? true : false;
};

let aboutYouObj = {};
let yourHomeObj = {};
let yourCoverObj = {};
let mappedQuoteApplication = {};
let payload = {
  response: {
    error_message: '',
    quote_reference: '',
    quote_timestamp: '',
    cover_premium: '0',
    premium_details: [
      {
        id: null,
        updated_date: null,
        item_name: '',
        sequence: 0,
        amount: 0,
        percentage: 0,
        product_variant: ''
      }
    ],
    acceptance_notifications: [
      {
        id: null,
        updated_date: null,
        description: '',
        notification_type: '',
        overidden: ''
      }
    ]
  }
};

const GetQuoteThankYouPage = (props: Props) => {

  const spinnerBackgroundColor = isMobile ? offWhite : whiteColor;
  const [errorType, setErrorType] = useState(props.match.params.type || '');
  const [requestStatus, setRequestStatus] = useState < RequestState > (
    REQUEST_LOADING
  );

  const checkResponse = (payload: PayloadType) => {
    // eslint-disable-next-line no-prototype-builtins
    if(payload.hasOwnProperty('message')) return false;
    if (!payload.error) {
      if (payload.acceptance_notifications && payload.acceptance_notifications.length > 0) {
        return false;
      }
      return true;
    }
    return false
  };

  const setRequestStatusFunction = (status: RequestState) => {
    setRequestStatus(status);
    amplifyAnalytics({
      name: 'HOME_GET_QUOTES',
      attributes: { status: status,
      username: getItemFromSessionStorage(BOI_WALLET_USER_NAME_STORAGE) }
    })
  }

  const isRSADown = (payload: PayloadType) =>
    payload.error &&
    payload.error_code &&
    payload.error_code === "EXTERNAL_SERVICE_UNAVAILABLE";

  const handleGetQuoteResponse = () => {
    if (isRSADown(payload) /* check for RSA errors*/) {
      setRequestStatus(REQUEST_FAILURE);
      setErrorType('rsa');
    } else if (payload) {
      if (Array.isArray(payload)) {
        payload.forEach((quote: PayloadType) => {
          if (checkResponse(quote)) {
            setRequestStatusFunction(REQUEST_SUCCESS);
            return
          }
        })
        setRequestStatusFunction(REQUEST_FAILURE);
      } else {
        checkResponse(payload) ? setRequestStatusFunction(REQUEST_SUCCESS) : setRequestStatusFunction(REQUEST_FAILURE)
      }
    } else {
      setRequestStatusFunction(REQUEST_FAILURE);
    }
  };

  const getHomeQuote = async () => {
    // Used to ensure a call to the backend is only made going forward through the journey
    const submittedGetQuote = getItemFromSessionStorage(`${HOME_INSURANCE_TYPE}${SUBMITTED_GET_QUOTE_STORAGE}`);
    removeFromSessionStorage(`${HOME_INSURANCE_TYPE}${SUBMITTED_GET_QUOTE_STORAGE}`);
    const quoteData = props.location.state || getItemFromSessionStorage(HOME_QUOTE_THANK_YOU_STORAGE);
    if (formDataReceived(quoteData) === true && submittedGetQuote) {
      const mappedHomeOwnerOccupier = mapGetQuote(
        quoteData.aboutYouObj,
        quoteData.yourHomeObj,
        quoteData.yourCoverObj,
        quoteData.assumptions,
        quoteData.extraQuestionsValues
      );
      mappedQuoteApplication = mappedHomeOwnerOccupier;
      payload = await postHomeQuote(
        mappedHomeOwnerOccupier,
        props
      );

      handleGetQuoteResponse();
    } else {
      setRequestStatusFunction(REQUEST_FAILURE);
    }
  };

  //running only once to post the payload
  const quoteData = props.location.state || getItemFromSessionStorage(HOME_QUOTE_THANK_YOU_STORAGE);
  useEffect(() => {
    if (quoteData) {
      aboutYouObj = quoteData.aboutYouObj;
      yourHomeObj = quoteData.yourHomeObj;
      yourCoverObj = quoteData.yourCoverObj;
    }
    getHomeQuote();
  }, [quoteData]);

  useEffect(() => {
    if (props.location.state) {
      const values = {
        ...props.location.state.aboutYouObj,
        ...props.location.state.yourHomeObj,
        ...props.location.state.yourCoverObj
      }
      saveInSessionStorage(`${HOME_INSURANCE_TYPE}${GET_QUOTE_VALUES_STORAGE}`, JSON.stringify(values));
    }
  }, [props.location.state]);

  useEffect(() => {
    const redirectback = getItemFromSessionStorage(REDIRECT_BACK)
    if(redirectback) {
      removeFromSessionStorage(REDIRECT_BACK)
      props.history.goBack();
    }
  }, [])

  const cProps = {
    aboutYouObj,
    yourHomeObj,
    yourCoverObj,
    mappedQuoteApplication,
    payload
  };

  const getLoadingComponent = () => <Loading {...cProps}
    text='Please wait, we are processing your quote for you'
    errorType={errorType}
    spinnerBackgroundColor={spinnerBackgroundColor} />;

  const getSuccessComponent = () => <Success {...cProps} errorType={errorType} />;

  const getErrorComponent = () => <GenericErrorPage {...cProps} errorType={errorType} />;

  const paths = {
    [REQUEST_LOADING]: getLoadingComponent,
    [REQUEST_SUCCESS]: getSuccessComponent,
    [REQUEST_FAILURE]: getErrorComponent
  };

  const Result = paths[requestStatus];
  return <Result />;
};

export default GetQuoteThankYouPage;
